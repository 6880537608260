<template>
  <div class="bg_F8F8F8">
    <Header :activeName="activeName" />
    <div class="mt_69 flex justify-center">
      <div class="fixed_width home_title mt_20 mb_20">
        首页>加入我们>职位详情
      </div>
    </div>

    <div class="flex justify-center">
      <div class="fixed_width blue_box fc_FFFFFF fz_30 font_blod">
        <div class="pd_30">{{ newsInfo.title }}</div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="fixed_width blue_border mt_10">
        <div class="pd_30 detail_content" v-html="newsInfo.content"></div>
      </div>
    </div>

    <div class="flex justify-center pb_60">
      <a href="mailto:liangjingjing@wxhkhr.com">
        <el-button
          type="primary"
          class="primary_button mt_30"
          @click="handleDelivery()"
        >
          投递简历</el-button
        ></a
      >
    </div>

    <Bottom />
  </div>
</template>

<script>
import Header from '@/components/header/index';
import Bottom from '@/components/bottom/index';
import { getNewsById } from '@/api/home';
export default {
  components: {
    Header,
    Bottom
  },
  data() {
    return {
      newsInfo: '',
      activeName: '加入我们'
    };
  },
  mounted() {
    getNewsById(this.$route.query.id).then((res) => {
      this.newsInfo = res.data;
    });
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.home_title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #111c2f;
}
.blue_box {
  // height: 90px;
  background: #4690f7;
}
.blue_border {
  border: 1px solid #4690f7;
}
.primary_button {
  width: 136px;
  height: 38px;
  background: #4690f7;
  border-radius: 5px;
}
a {
  color: #fff;
  border-bottom: 0;
  text-decoration: auto;
}
.detail_content {
  ::v-deep {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>